import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import HeroSection from "./components/Hero";
import About from "./pages/about";

function App() {
  return (
    <Router>
      <div className="App">
        {/* Static Navbar */}
        <Navbar />

        {/* Routes */}
        <Routes>
          <Route path="/" element={<HeroSection />} />
          <Route path="/about" element={<About />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
