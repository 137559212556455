import React from "react";
import MainImage from "../assets/main.png";
import { useNavigate } from "react-router-dom";

const HeroSection = () => {
  const navigate = useNavigate();

  const goToAboutPage = () => {
    navigate("/about");
  };

  return (
    <div className="relative flex w-full flex-col md:flex-row bg-[#241F1C] py-4">
      {/* Left Section: Title, Description, and Join Button */}
      <div className="w-1/2 flex flex-col justify-center items-start py-4 px-12">
        <h1 className="text-5xl font-bold text-white mb-4">
          Welcome to <span className="text-[#FF6400]">Pro Cube</span>
        </h1>
        <p className="text-lg mb-6 text-white font-light">
          Discover a new way to connect and engage with people around the world.
          Join us today and be part of something amazing!
        </p>
        <button className="px-6 py-3 bg-[#FF6400] text-white font-semibold rounded-md  transition">
          Join Now
        </button>
      </div>

      {/* Right Section: Image and Buttons */}
      <div className="relative w-[600px] flex justify-center items-center">
        <div className="relative w-[568px] h-auto">
          <img
            src={MainImage}
            alt="Hero"
            className="relative z-20 w-full h-full object-cover"
          />
          {/* Buttons */}
          <button
            onClick={goToAboutPage}
            style={{
              transform: "skew(16deg) rotateZ(24deg)",
            }}
            className="absolute z-30 w-28 h-[90px] top-[165px] left-[302px] bg-transparent px-6 py-8 text-white font-semibold rounded-md transition hover:shadow-[0_0_30px_15px_rgba(255,223,0,0.2)]"
          ></button>
          <button
            onClick={goToAboutPage}
            style={{
              transform:
                "rotate(-1deg) translate(0px, 0px) scale(0.9, 0.9) skew(-2deg, 18deg)",
            }}
            className="absolute z-30 top-[154px] left-[442px] bg-transparent px-6 py-8 text-white font-semibold rounded-md transition hover:shadow-[0_0_30px_15px_rgba(255,223,0,0.2)]"
          ></button>
          <div className="absolute z-10 w-[428px] h-[428px] top-24 left-16 rounded-[300px] shadow-[0_0_50px_75px_rgba(255,100,0,0.2)]"></div>
        </div>
      </div>

      <div className="absolute z-40 border border-2 text-white cursor-pointer px-4 py-2 border-[#FF6400] rounded-3xl bottom-16 right-24">
        Click To View
      </div>
    </div>
  );
};

export default HeroSection;
